<template>
<!-- 百姓健身房指数排名 -->
    <div class="fitness darkBack">
        <div class="fitnessnav">
            <div>活跃人群时间活跃度</div>
            <div>
                <!-- <span class="selecte" id="selected" @click="selectedhandle">月</span> -->
                <span class="selecte" @click="selectedhandle">日</span>
                <span class="selecte" @click="selectedhandle">周</span>
            </div>
        </div>
        <div id="week1" style="width:5.70rem; height:3.20rem;"></div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            sport:window.setData('百姓健身房-无法进入-运动人群时间活跃度', {
                data: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
                number:[120, 200, 150, 80, 70, 110, 130],
            }),
        }
    },
    methods: {
        selectedhandle(e){          // 点击切换样式
            // $('.selecte').class('background', "#000000")
            $('.selecte').css({'background-color':'#00000000', 'color':'#838181'})
            e.target.style.background = "#7EA7E2";
            e.target.style.color = "#000000";
            // 切换柱状图表
            var switchover = e.target.innerText
        },
        // 柱状图
        fitness(id, number){
            let canye = this.$echarts.init(document.getElementById(id));
            // var data = number.number
            // var data = [];
            // for (let i = 0; i < 17; ++i) {
            //     data.push(Math.round(Math.random() * 200));
            // }

            let option = {
                xAxis: {
                    type: 'category',
                    offset: 5,          // x轴 字与轴的距离
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: '#7EA7E2',
                        }
                    },
                    axisTick: {                 // y轴刻度线
                        show:false,
                        alignWithLabel: true,
                    },
                    data: number.data
                },
                yAxis: {
                    type: 'value',
                    splitLine: {            // 网格线的颜色
                        show: true,
                        splitNumber: 5,
                        lineStyle:{
                            color: ['#2D94D040'],
                            width: 1,
                            type: 'solid',
                        },
                　　},
                    axisLabel: {        // 隐藏y轴数据
                        formatter: function () {
                            return "";
                        }
                    },
                },
                series: [{
                    data: number.number,
                    type: 'bar',
                    barWidth : '40%',              // 柱子的宽度
                    itemStyle: {
                        normal: {
                            color: this.$echarts.graphic.LinearGradient(0, 1 , 0, 0, [{
                                offset: 0,
                                color: "#2D94D0" // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: "#3AE6CE" // 100% 处的颜色
                            }], false)
                        }
                    },
                }]
            };;
                canye.setOption(option);
            // }
            //图表自动缩放
            window.addEventListener("resize",function(){
                canye.resize();
            });
        }
    },
    mounted () {
        this.fitness("week1", this.sport)
    }
}
</script>

<style scoped>
.fitness{
    width:5.94rem;
    height:3.63rem;
    font-size: 0.18rem;
    color:#fff;
    padding: 0.2rem;
    position: relative;
}
.fitnessnav{
    display: flex;
    justify-content: space-between;
}
.fitnessnav>div:nth-child(2){
    display: flex;
    justify-content: space-around;
    width:1.05rem;
    height:0.22rem;
    border:0.01rem solid #838181;
    border-radius: 0.11rem;
}

.selecte{
    width:50%;
    text-align: center;
    height:0.22rem;
    line-height: 0.22rem;
    display: inline-block;
    font-size: 0.12rem;
    cursor: pointer;
    color:#838181;
}

.selecte:nth-child(1){
    background-color:#7EA7E2;
    border-radius: 0.11rem 0rem 0rem 0.11rem;
    color:#000000;
}

.selecte:nth-child(2){
    /* background:red; */
    border-radius: 0rem 0.11rem 0.11rem 0rem;
}
</style>